import React, { useMemo } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  Container,
  Content,
  TitlePartner,
  DescriptionPartner,
  WrapperItem,
  SlideItem,
  LogoImg,
} from './styles.js';

import logoNVIDIAImg from './assets/logoNVIDIA.png';
import logousp from './assets/usp-logo.svg';
import logounifesp from './assets/logo-unifesp.svg';
import logofiap from './assets/logofiap.svg';
import logofinep from './assets/logofinep.svg';
import logoembrapi from './assets/logoembrapi.svg';
import logocrea from './assets/logocrea.svg';
import logosingularity from './assets/logosingularity.svg';
import loloopenai from './assets/loloopenai.svg';
import logoanthropic from './assets/logoanthropic.svg';
import logogoogle from './assets/logogoogle.svg';

import { FadeInScroll } from '../../../../utils/fadeInScroll/index.js';

export const Partners = () => {
  const partnerData = useMemo(() => ([
    { benefitImg: logoNVIDIAImg, alt: "NVIDIA-Imagem", width: "220px", order: 1 },
    { benefitImg: logogoogle, alt: "logogoogle", width: "220px", order: 2 },
    { benefitImg: loloopenai, alt: "loloopenai", width: "210px", order: 3 },
    { benefitImg: logoanthropic, alt: "logoanthropic", width: "210px", order: 4 },
    { benefitImg: logousp, alt: "USP-Imagem", width: "180px", order: 5 },
    { benefitImg: logounifesp, alt: "Unifesp-Imagem", width: "210px", order: 6 },
    { benefitImg: logosingularity, alt: "logosingularity", width: "210px", order: 7 },
    { benefitImg: logofiap, alt: "logofiap", width: "210px", order: 8 },
    { benefitImg: logofinep, alt: "logofinep", width: "210px", order: 9 },
    { benefitImg: logoembrapi, alt: "logoembrapi", width: "210px", order: 10 },
    { benefitImg: logocrea, alt: "logocrea", width: "210px", order: 11 },
  ]), []);

  const sortedPartnerData = useMemo(() => {
    return [...partnerData].sort((a, b) => a.order - b.order);
  }, [partnerData]);

  // Configurações para movimento contínuo e suave
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 15000,           // duração total da animação (ajuste conforme necessário)
    autoplay: true,
    autoplaySpeed: 0,       // movimento contínuo sem pausa
    cssEase: 'linear',
    slidesToShow: 5,
    slidesToScroll: 1,
    pauseOnHover: false,
    draggable: false,
    swipe: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <Container>
      <Content>
        <FadeInScroll id="Title-Partner" sx={{ txi: '50px', tyi: '0px' }}>
          <TitlePartner id="Title-Partner">
            Parcerias Estratégicas
          </TitlePartner>
        </FadeInScroll>

        <FadeInScroll id="Description-Partner" sx={{ txi: '-50px', tyi: '0px' }}>
          <DescriptionPartner id="Description-Partner">
            Para garantir que a pesquisa se transforme em soluções viáveis, a Vega Robotics estabelece colaborações estratégicas com universidades, institutos de pesquisa, startups e indústrias. Essas parcerias impulsionam o desenvolvimento de tecnologias inovadoras alinhadas às necessidades do setor.
          </DescriptionPartner>
        </FadeInScroll>

        <FadeInScroll id="Wrapper-Item" sx={{ txi: '0px', tyi: '50px' }}>
          <WrapperItem id="Wrapper-Item">
            <Slider {...settings}>
              {sortedPartnerData.map((item, index) => (
                <SlideItem key={index}>
                  <LogoImg src={item.benefitImg} alt={item.alt} widthProp={item.width} />
                </SlideItem>
              ))}
            </Slider>
          </WrapperItem>
        </FadeInScroll>
      </Content>
    </Container>
  );
};
  