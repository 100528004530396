import React from 'react';
import { scroller } from 'react-scroll';

import {
  Container,
  Content,
  Title,
  SubTitle,
  CTAButton,
  WhatsApp
} from "./styles.js";

import whatsApp from "../../../../assets/whatsapp.svg";
import { FiArrowUpRight } from "react-icons/fi";
import planetImg from '../../assets/planet.png';

export const Welcome = () => {
  const handleCTAClick = () => {
    scroller.scrollTo('subtitle-after-video', {
      duration: 1000,           // Duração da rolagem em milissegundos
      smooth: 'easeInOutQuint', // Tipo de transição suave
      offset: -130,              // Ajuste de deslocamento (útil se houver header fixo)
    });
  };

  return (
    <Container id="home">
      <Content imgprops={planetImg}>
        <Title>
          Transformando Pesquisa em Soluções para o 
          <br />
          Futuro da Indústria
        </Title>

        <SubTitle>
        A Vega Robotics atua na promoção da pesquisa aplicada e inovação tecnológica, atuando na integração entre academia e indústria para o desenvolvimento de soluções disruptivas. Nosso foco é transformar avanços científicos e tecnológicos em soluções práticas, contribuindo para a eficiência, segurança e sustentabilidade da indústria.
        </SubTitle>

        <CTAButton onClick={handleCTAClick}>
          Eu quero conhecer
          <FiArrowUpRight color="#fff" />
        </CTAButton>
      </Content>

      <WhatsApp
        href="https://wa.me/+5511950091919"
        target="_blank"
        rel="noreferrer"
      >
        <img src={whatsApp} alt="logo-whatsapp" />
      </WhatsApp>
    </Container>
  );
};
