import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-image: linear-gradient(180deg, #0C0D0F, var(--color-background));

  #Wrapper-Item {
    width: 100%;
  }

  hr {
    bottom: 400px;
    height: 1px;
    width: 100%;
    border: none;
    position: absolute;
    background: radial-gradient(#3D3D40 10%, var(--color-background));
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 10rem 0 -10rem;
`;

export const TitlePartner = styled.h3`
  margin-bottom: 24px;
  padding: 0 3rem;
  font-size: 4.8rem;
  line-height: 54px;
  text-align: center;
  color: var(--color-gray);

  @media only screen and (max-width: 580px) {
    font-size: 4rem;
  }

  @media only screen and (min-width: 1900px) {
    font-size: 5.2rem;
    line-height: 6rem;
    margin-bottom: 3rem;
  }
`;

export const DescriptionPartner = styled.p`
  display: block;
  width: 100%;
  max-width: 850px;
  padding: 0 3rem;
  font-size: 1.6rem;
  line-height: 26px;
  text-align: center;
  color: var(--color-gray);

  @media only screen and (max-width: 580px) {
    font-size: 1.3rem;
  }

  @media only screen and (min-width: 1900px) {
    font-size: 2rem;
    line-height: 3rem;
    max-width: 650px;
  }
`;

/* Wrapper do slider com ajustes para forçar hardware acceleration e suavizar a animação */
export const WrapperItem = styled.div`
  width: 100%;
  margin-top: 50px;

  .slick-list {
    overflow: hidden;
  }

  .slick-track {
    display: flex !important;
    flex-wrap: nowrap;
    will-change: transform;
    transform: translate3d(0, 0, 0);
  }

  .slick-slide {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    margin: 0 10px !important; /* margem reduzida para suavizar a transição */
  }
`;

export const SlideItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  height: 300px;
`;

export const LogoImg = styled.img`
  width: ${({ widthProp }) => widthProp || '200px'};
  max-height: 100%;
  height: auto;
  filter: grayscale(500%);
  transition: filter 0.3s;
  backface-visibility: hidden;
`;
