import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(180deg, #0C0D0F, transparent);
  /* padding: 0 0 100rem; */
  margin-top: -65px;

  hr {
    margin-top: 8rem;
    height: 1px;
    width: 100%;
    border: none;
    background: radial-gradient(#3D3D40 10%, var(--color-background));
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1150px;
  /* padding: 0 0 -100px; */
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.6;
  text-align: left;

  p {
    margin-bottom: 1rem;
  }

  ul {
    margin: 1rem 0;
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 0.5rem;

      &:before {
        content: '✔';
        margin-right: 0.5rem;
        color: var(--accent-color, #4caf50);
      }
    }
  }

  @media only screen and (max-width: 590px) {
    padding: 0 1rem;
    font-size: 0.9rem;
  }
`;
