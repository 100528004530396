import React from 'react';
import { Container, Content } from './styles';
import { FadeInScroll } from '../../../../utils/fadeInScroll';

export const TabPanel = () => {
  return (
    <Container>
      <FadeInScroll id="Content-Panel" sx={{ txi: '-50px', tyi: '0px' }}>
        <Content>
          <p>
            Promovemos inovação através da pesquisa e desenvolvimento (P&D) de novas tecnologias para industria.
          </p>
          <p>Nossa atuação abrange:</p>
          <ul>
            <li>
              <strong>Pesquisa aplicada:</strong> Desenvolvimento de soluções inovadoras em parceria com universidades
              e centros de pesquisa.
            </li>
            <li>
              <strong>Engenharia e automação:</strong> Integração de novas tecnologias ao ambiente industrial.
            </li>
            <li>
              <strong>Conexão academia-indústria:</strong> Aplicação direta dos resultados de pesquisa para solucionar
              desafios industriais.
            </li>
            <li>
              <strong>Fomento à inovação:</strong> Projetos viabilizados por investimentos da iniciativa privada e
              agências de fomento.
            </li>
          </ul>
        </Content>
      </FadeInScroll>
      <hr />
    </Container>
  );
};
