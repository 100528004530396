import React from 'react';
import {
  Container,
  Content,
  WrapperText,
  Div,
  Subtitle
} from './styles';
import { FadeInScroll } from '../../../../utils/fadeInScroll';

export const Movie = () => {
  return (
    <Container>
      <Content>
        <FadeInScroll id="videoBanner" sx={{ txi: '0px', tyi: '50px' }}>
          {/* Conteúdo do vídeo (atualmente comentado) */}
        </FadeInScroll>
        <WrapperText>
          <Div />
          {/* Atribuímos o id apenas ao FadeInScroll para evitar duplicidade */}
          <FadeInScroll id="subtitle-after-video" sx={{ txi: '50px', tyi: '0px' }}>
            <Subtitle>
              Do Conhecimento à Inovação: Pesquisa Aplicada para a Indústria
            </Subtitle>
          </FadeInScroll>
        </WrapperText>
      </Content>
    </Container>
  );
};
